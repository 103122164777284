import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import TimesheetDashboard from "./components/TimesheetDashboard";
import OverallAnalysis from "./components/OverallAnalysis";
import Dashboard from "./components/Dashboard";
import TimeOffComponent from "./components/TimeOffComponent";
import ProjectAnalysis from "./components/ProjectAnalysis";
import LoginComponent from "./components/Login";
import ProtectedRoute from "./auth/ProtectedRoute";
import AttendanceByDate from "./components/atOffice";
import AttendanceByUser from "./components/attendance";
import Utilization from "./components/Utilization";

const App = () => {
  return (
    <Router>
      <Navbar /> {/* Make sure Navbar renders across all pages */}
      <Routes>
        {/* Public route: Login */}
        <Route path="/login" element={<LoginComponent />} />

        {/* Protected routes: Dashboard and its sub-routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute> {/* Wrap ProtectedRoute around Dashboard */}
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route
            path="overall-analysis"
            element={
              <ProtectedRoute>
                <OverallAnalysis />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-analysis"
            element={
              <ProtectedRoute>
                <TimesheetDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="project-analysis"
            element={
              <ProtectedRoute>
                <ProjectAnalysis />
              </ProtectedRoute>
            }
          />
          <Route
            path="timeOff"
            element={
              <ProtectedRoute>
                <TimeOffComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="attendance"
            element={
              <ProtectedRoute>
                <AttendanceByDate />
              </ProtectedRoute>
            }
          />
           <Route
            path="Utilization"
            element={
              <ProtectedRoute>
                <Utilization />
              </ProtectedRoute>
            }
          />
            <Route
            path="user-attendance"
            element={
              <ProtectedRoute>
                <AttendanceByUser />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* Redirect root path to user-analysis */}
        <Route path="/" element={<Navigate to="/dashboard/user-analysis" replace />} />
      </Routes>
    </Router>
  );
};

export default App;