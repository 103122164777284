import React, { useState, useEffect } from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import ProgressLoader from '../utensils/ProgressLoader';
import axios from "axios";
import {
  Button,
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { startOfWeek, endOfWeek } from "date-fns";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

// const StyledContainer = styled(Container)({
//   backgroundColor: "#FFFFFF",
//   padding: "30px",
//   borderRadius: "16px",
//   boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
//   maxWidth: "100%",
// });
const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  height: "auto", // Use full viewport height
  backgroundColor: "#FFFFFF",
  padding: "30px",
  borderRadius: "16px",
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
  maxWidth: "100%",
});

const TableContainer = styled("div")({
flex:1,
overflowY: "auto",
 // Allows the table to take up the remaining height
  // Enables vertical scrolling for the table
  marginTop: "60px", // Adds spacing below the chart
  border: "1px solid #ddd",
  borderRadius: "12px",
});

const Title = styled("h1")({
  textAlign: "center",
  color: "#0d47a1",
  marginBottom: "30px",
  fontSize: "2.5rem",
  fontFamily: "Roboto, sans-serif",
});
const StyledTableCell = styled(TableCell)({
  position: "sticky",
  top: 0,
  backgroundColor: "#f1f1f1", // Background color for sticky header
  fontWeight: "bold",
  zIndex: 2, // Ensures it stays above other row
  textAlign: "center",
});

// Function to calculate background color based on utilization
const calculateBackgroundColor = (utilization) => {
  if (utilization > 0) {
    return `rgba(144, 238, 144, ${Math.min(0.2 + utilization / 100, 0.8)})`; // Subtle green
  } else if (utilization < 0) {
    return `rgba(255, 99, 71, ${Math.min(0.2 + Math.abs(utilization) / 100, 0.8)})`; // Subtle red
  }
  return "transparent"; // No color for zero utilization
};

const Utilization = () => {
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("top5Over");
  const [sortBy, setSortBy] = useState("utilization"); 
  const [sortDirection, setSortDirection] = useState("desc"); // "asc" or "desc"
  
  const handleMenuChange = (event) => {
    setSelectedOption(event.target.value);
  };
  // Fetch projects and users
  useEffect(() => {
    const fetchProjectsAndUsers = async () => {
      try {
        // Fetch projects
        const projectResponse = await axios.get(
          "https://timesheet-be.fleetstudio.com/api/project/projects"
        );
        const activeProjects = projectResponse.data.filter(
          (project) => project.status === "active"
        );
        setProjects(activeProjects);

        // Fetch users
        const userResponse = await axios.get(
          "https://timesheet-be.fleetstudio.com/api/user/getUsers"
        );
        const allUsers = userResponse.data.map((user) => ({
          id: user._id,
          name: `${user.fname} ${user.lname}`.trim(),
        }));
        setUsers(allUsers);
        setFilteredUsers(allUsers); // Initially show all users
      } catch (error) {
        console.error("Error fetching projects or users:", error);
      }
    };

    fetchProjectsAndUsers();
    setLoading(false);
  }, []);

  const handleSubmit = async () => {
    if (!selectedWeek) return;

    setLoading(true);
    try {
      // Calculate the start and end dates of the selected week
      const startDate = startOfWeek(selectedWeek, { weekStartsOn: 1 }); // Week starts on Monday
      const endDate = endOfWeek(selectedWeek, { weekStartsOn: 1 });

      const apiUrl = `https://timesheet-be.fleetstudio.com/api/user/reports/filter/${startDate
        .toISOString()
        .split("T")[0]}/${endDate.toISOString().split("T")[0]}`;

      const response = await axios.get(apiUrl);
      const rawData = response.data;

      const transformedData = rawData.map((item) => ({
        ...item,
        name: item.name,
      }));

      setData(transformedData);
      setFilteredData(transformedData); // Initialize with all data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleProjectFilter = (projectId) => {
    setSelectedProject(projectId);
    let filteredNames;

    if (projectId) {
      // Filter data based on selected project
      const projectFilteredData = data.filter((user) =>
        user.projects.some((project) => project.projectId === projectId)
      );
      setFilteredData(projectFilteredData);

      // Filter names based on selected project
      filteredNames = users.filter((user) =>
        projectFilteredData.some((userData) => userData.userId === user.id)
      );
    } else {
      setFilteredData(data); // Reset to all data
      filteredNames = users; // Reset to all names
    }

    setFilteredUsers(filteredNames);
    setSelectedName(""); // Reset name filter
  };

  const handleNameFilter = (userId) => {
    setSelectedName(userId);

    if (userId) {
      const nameFilteredData = data.filter((user) => user.userId === userId);

      if (selectedProject) {
        const projectFilteredData = nameFilteredData.filter((user) =>
          user.projects.some((project) => project.projectId === selectedProject)
        );
        setFilteredData(projectFilteredData);
      } else {
        setFilteredData(nameFilteredData);
      }
    } else {
      handleProjectFilter(selectedProject);
    }
  };


  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Hardcoded week dates
        const startDate = "2024-12-01";
        const endDate = "2024-12-08";

        // Fetch projects
        const projectResponse = await axios.get(
          "https://timesheet-be.fleetstudio.com/api/project/projects"
        );
        const activeProjects = projectResponse.data.filter(
          (project) => project.status === "active"
        );
        setProjects(activeProjects);

        // Fetch users
        const userResponse = await axios.get(
          "https://timesheet-be.fleetstudio.com/api/user/getUsers"
        );
        const allUsers = userResponse.data.map((user) => ({
          id: user._id,
          name: `${user.fname} ${user.lname}`.trim(),
        }));
        setUsers(allUsers);
        setFilteredUsers(allUsers);

        // Fetch utilization data for the hardcoded week
        const apiUrl = `https://timesheet-be.fleetstudio.com/api/user/reports/filter/${startDate}/${endDate}`;
        const response = await axios.get(apiUrl);
        const rawData = response.data.map((item) => ({
          ...item,
          name: item.name,
        }));
        const sortedData = sortData(rawData, "utilization", "desc");
        setData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInitialData();
  }, []);

  const sortData = (data, key, direction) => {
    return [...data].sort((a, b) => {
      if (direction === "asc") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
  };

  const handleSort = (key) => {
    const newDirection = sortBy === key && sortDirection === "asc" ? "desc" : "asc";
    const sortedData = sortData(filteredData, key, newDirection);
    setSortBy(key);
    setSortDirection(newDirection);
    setFilteredData(sortedData);
  };

  const renderSortIcon = (key) => {
    const isCurrentColumn = sortBy === key;
    return (
      <span style={{ marginLeft: "8px", display: "inline-block" }}>
        {isCurrentColumn && sortDirection === "asc" ? (
          <FaArrowUp />
        ) : (
          <FaArrowDown />
        )}
      </span>
    );
  };

  const renderChart = () => {
    if (!filteredData.length) return null;

    // Sort data by utilization in descending order for over-utilized or ascending for under-utilized
    const sortedData =
      selectedOption === "top5Over" || selectedOption === "top10Over"
        ? [...filteredData].sort((a, b) => b.utilization - a.utilization)
        : [...filteredData].sort((a, b) => a.utilization - b.utilization);

    // Select data based on menu option
    const topData =
      selectedOption === "top5Over" || selectedOption === "top5Under"
        ? sortedData.slice(0, 5)
        : sortedData.slice(0, 10);
console.log(topData)
    // Prepare the chart data
    const chartData = topData.map((user) => ({
      name: user.name,
      utilization: user.utilization,
      billable: user.billable,
      allocatedHours: user.allocatedHours,
    }));

    return (
      <div className="min-h-[400px]" style={{ width: "100%", marginTop: "20px" }}>
        {/* Title */}
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <Typography variant="h6" gutterBottom style={{ fontSize: "30px", fontWeight: "bold" }} >
            User Utilization Overview
          </Typography>
        </div>
    
        {/* Menu Bar */}
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
          <FormControl variant="outlined" style={{ minWidth: 200 }}>
            <InputLabel>Choose</InputLabel>
            <Select value={selectedOption} onChange={handleMenuChange} label="Menu">
              <MenuItem value="top5Over">Top 5 Over Utilized</MenuItem>
              <MenuItem value="top10Over">Top 10 Over Utilized</MenuItem>
              <MenuItem value="top5Under">Top 5 Under Utilized</MenuItem>
              <MenuItem value="top10Under">Top 10 Under Utilized</MenuItem>
            </Select>
          </FormControl>
        </div>
    
        {/* Chart */}
        <ResponsiveContainer width="100%" minHeight="400px">
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fontSize: 12 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="utilization" fill="#8884d8" name="Utilization" />
            <Bar dataKey="billable" fill="#82ca9d" name="Billable Hours" />
            <Bar dataKey="allocatedHours" fill="#ff7300" name="Allocated Hours" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };    


  return (
    <StyledContainer>
      <Title>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h1 className="sticky top-0 mb-4 text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl flex justify-center">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
              Utilization
            </span>
          </h1>
          
        </div>
        <div
          style={{
            borderBottom: "2px solid #0d47a1",
            marginBottom: "20px",
          }}
        ></div>
      </Title>
      <div
        className="sticky top-0 bg-white z-10"
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "20px",
          alignItems: "center",
          paddingBottom: "8px",
          paddingTop: "10px",
        }}
      >
        {/* Filters */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select Week"
            views={["year", "month", "day"]}
            value={selectedWeek}
            onChange={(newValue) => setSelectedWeek(newValue)}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="yyyy-MM-dd"
            minDate={new Date("2024-11-25")} 
          />
        </LocalizationProvider>
  {/* use the below form incase a project filter is needed */}
        {/* <FormControl
          variant="outlined"
          style={{ minWidth: 200 }}
          disabled={data.length === 0}
        >
          <InputLabel>Project</InputLabel>
          <Select
            value={selectedProject}
            onChange={(e) => handleProjectFilter(e.target.value)}
            label="Project"
          >
            <MenuItem value="">All Projects</MenuItem>
            {projects.map((project) => (
              <MenuItem key={project._id} value={project._id}>
                {project.projectName}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
  
        <FormControl
          variant="outlined"
          style={{ minWidth: 200 }}
          disabled={data.length === 0}
        >
          <InputLabel>User</InputLabel>
          <Select
            value={selectedName}
            onChange={(e) => handleNameFilter(e.target.value)}
            label="Name"
          >
            <MenuItem value="">All Names</MenuItem>
            {filteredUsers.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading || !selectedWeek}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
        </Button>
      </div>
<div>
      {renderChart()}
</div>

      {filteredData.length > 0 && (
        <>
        <div>
          <TableContainer>
            <Paper
              style={{
              
                borderRadius: "12px",
                overflowY: "auto",
                height: "100%",
                maxHeight: "800px" // Ensure table fills available space
              }}
            >
              <Table
              stickyHeader
                // style={{
                //   borderCollapse: "collapse",
                //   width: "100%",
                // }}
              >
                <TableHead
                 style={{
                  backgroundColor: "#f1f1f1",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}>
                  <TableRow
                    style={{
                      backgroundColor: "#f1f1f1",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSort("name")}
                        title="Name"
                      >
                        Name {renderSortIcon("name")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell title="Allocated Hours" style={{ fontWeight: "bold" }}>
                      Alloc
                    </StyledTableCell>
                    <StyledTableCell title="Logged Hours" style={{ fontWeight: "bold" }}>
                      Log 
                    </StyledTableCell>
                    <StyledTableCell title="Billable" style={{ fontWeight: "bold" }}>
                      B
                    </StyledTableCell>
                    <StyledTableCell title="Non-billable" style={{ fontWeight: "bold" }}>
                      N.B
                    </StyledTableCell>
                    <StyledTableCell title="Learning" style={{ fontWeight: "bold" }}>
                      Learn
                    </StyledTableCell>
                    <StyledTableCell title="Holiday" style={{ fontWeight: "bold" }}>
                      Hol
                    </StyledTableCell>
                    <StyledTableCell title="Leave" style={{ fontWeight: "bold" }}>
                      LV
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSort("utilization")}
                        title="Utilization"
                      >
                        Util {renderSortIcon("utilization")}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell title="Comment" style={{ fontWeight: "bold" }}>
                      Cmt
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, idx) => (
                    <TableRow
                      key={idx}
                      style={{
                        backgroundColor: calculateBackgroundColor(
                          row.utilization
                        ),
                        transition: "background-color 0.3s ease",
                      }}
                    >
                      <TableCell style={{ padding: "10px" }}>{row.name}</TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.allocatedHours}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.loggedHours}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.billable}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.nonBillable}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.learning}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.holiday}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.leave}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.utilization}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {row.comment.join(", ")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </TableContainer>
          </div>
        </>
      )}
    </StyledContainer>
  );
  
};

export default Utilization;